import React from "react";
import { AlertHistoryComponent } from '../../components';

const Home = () => {
  const loading = false; // useSelector((state) => state.authReducer.loading);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div><AlertHistoryComponent/></div>
      )}
    </div>
  );
};

export default Home;
