import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../context/auth.context";
import MobileLayout from "../mobile/MobileLayout";
import DesktopLayout from "../desktop/DesktopLayout";

export default function AppLayout() {
  const isMobileSize = window.innerWidth <= 425;

  const { currentUser } = useContext(AuthContext);

  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (currentUser && !currentUser.onboardingCompleted) {
      return history("/register");
    }
  }, [location, currentUser, history]);

  // const handleItemClick = async (name) => {
  //   if (name === 'Sign out') {
  //     await logoutUser();
  //   }
  // };

  if (isMobileSize) {
    return <MobileLayout />;
  }

  return <DesktopLayout />;
}
