import { Heading, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';

function ContentHeader({
  title,
  subTitle,
  description,
  headingStyle,
  descriptionStyle,
  size,
}) {
  return (
    <Fragment>
      <Heading
        as='h4'
        color='purchr.header'
        fontWeight={size === 'small' ? '600' : '700'}
        fontSize={
          size === 'small'
            ? { base: '20px', sm: '24px' }
            : { base: '30px', sm: '42px' }
        }
        {...headingStyle}>
        {title}
      </Heading>
      {subTitle ? (
        <Text
          fontSize='14px'
          fontWeight='700'
          color='purchr.brown.dark'
          textDecoration='underline'>
          {subTitle}
        </Text>
      ) : (
        <Fragment></Fragment>
      )}
      <Text
        fontSize={size === 'small' ? '12px' : '14px'}
        color='purchr.subHeader'
        {...descriptionStyle}>
        {description}
      </Text>
    </Fragment>
  );
}

export default ContentHeader;
