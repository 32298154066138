import {
  Box,
  Image,
  Menu,
  MenuList,
  MenuButton,
  Text,
  Icon,
  HStack,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FaChevronDown, FaCheck } from 'react-icons/fa';
import Vector from '../../assets/Vector.jpg';
import { uniqBy } from 'lodash';

const AlertHistoryDropDown = ({
  currentPageAlertItems,
  selectedItem,
  handleSelectItem,
  handleClearSelection,
}) => {
  const [isDropDrownOpen, setIsDropDrownOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()


  currentPageAlertItems = uniqBy(currentPageAlertItems, 'productId');
  
  return (
    <Box>
      <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
        <MenuButton
          w='170px'
          p='4px 8px 4px 4px'
          borderRadius='4px'
          border='1px solid'
          borderColor='#E6E6E6'
          bg='white'
          fontSize='14px'
          color='purchr.gray.700'
          fontWeight='600'>
          <Flex alignItems='center' justifyContent='space-between' gap='2px'>
            {selectedItem ? (
              <HStack gap='8px' maxWidth='140px'>
                <Image src={selectedItem?.thumbnailURL} w='20px' h='20px' />
                <Text
                  overflowX='hidden'
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'>
                  {selectedItem?.name}
                </Text>
              </HStack>
            ) : (
              <Text>Select item</Text>
            )}
            <Icon as={FaChevronDown} w='12px' h='12px' />
          </Flex>
        </MenuButton>

        <MenuList
          p='0px'
          borderRadius='8px'
          pos='relative'
          borderColor='#F2F4F7'
          boxShadow='0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);'>
          <HStack
            p='12px 8px 12px 24px'
            justifyContent='space-between'
            color='purchr.gray.light'
            fontWeight='400'
            pos='sticky'
            top='0px'
            bg='white'
          >
            <Text
              fontSize='8px'
              cursor='pointer'
              onClick={handleClearSelection}
            >{`Clear selection >`}</Text>
            <Image
              src={Vector}
              w='12px'
              h='12px'
              cursor='pointer'
              onClick={onClose}
            />
          </HStack>

          <Box
            maxW='320px'
            maxH='320px'
            overflowY='auto'
            style={{ scrollbarWidth: 'thin' }}
          >
            {currentPageAlertItems &&
              currentPageAlertItems.map((item, index) => {
                const isSelected = selectedItem?._id === item.productInfo._id;

                return (
                  <Box
                    key={index}
                    cursor='pointer'
                    minH='48px'
                    p='8px 12px'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    bg={isSelected ? 'purchr.brown.dark' : '#fff'}
                    color={isSelected ? '#fff' : 'purchr.header'}
                  >
                    <Box
                      display='flex'
                      gap='8px'
                      alignItems='center'
                      onClick={() => handleSelectItem(item.productInfo)}
                    >
                      <Image
                        src={item.productInfo.thumbnailURL}
                        w='44px'
                        h='44px'
                        borderRadius='2px'
                      />
                      <Text
                        fontSize='16px'
                        fontWeight='500'
                        maxWidth='169px'
                        overflowX='hidden'
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'
                        title={item.productInfo.name}
                      >
                        {item.productInfo.name}
                      </Text>
                    </Box>
                    {isSelected && (
                      <Box>
                        <Icon as={FaCheck} h='20px' w='20px' />
                      </Box>
                    )}
                  </Box>
                );
              })}
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AlertHistoryDropDown;
