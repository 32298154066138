import React from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Tag,
  TagLabel,
  Text,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import ConFetti from '../../assets/confetti.svg';
import BellRinging from '../../assets/notification-vactor.svg';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

const AlertHistoryItem = ({ alertItem }) => {
  const navigate = useNavigate();
  const discountPercentage = Math.round(
    ((alertItem.savedItemInfo.originalPrice - alertItem.scrapedPrice) /
      alertItem.savedItemInfo.originalPrice) *
      100
  )
  
  let discountPercentagevalue = `${discountPercentage}%`;
  if(discountPercentage < 0){
    discountPercentagevalue = `+${Math.abs(discountPercentage)}%`
  }
  else if (discountPercentage > 0){
    discountPercentagevalue = `-${discountPercentage}%`
  }

  return (
    <Grid
      bg='white'
      templateColumns='repeat(10,1fr)'
      alignItems='center'
      borderRadius='8px'
      mt='8px'
      gap={{ base: '0px', md: '4px' }}
      p={{ base: '4px 8px', md: '8px 12px', xl: '8px 20px' }}
    >
      <GridItem
        colSpan='1'
        color='purchr.gray.light'
        fontSize={{ base: '8px', '2xl': '12px' }}
        fontWeight='400'
        display={{ base: 'none', xl: 'block' }}
        minW='120px'
      >
        {moment(alertItem.scrapedAt).format('Do MMM @ h:mma')}
      </GridItem>

      <GridItem colSpan={{ base: '5', md: '6', lg: '4' }}>
        <Flex flex='1' alignItems='center' gap={{ base: '12px', xl: '20px' }}>
          <Box w='40px' h='40px'>
            <Image
              src={alertItem.productInfo.thumbnailURL}
              minW='40px'
              h='40px'
              borderRadius='4px'
            />
          </Box>

          <Box display='flex' flexWrap='nowrap' flexDirection='column'>
            <Link
              href={alertItem.productInfo.URL}
              color='purchr.blue.dark'
              fontSize='12px'
              textDecoration='underline'
              fontWeight='700'
              maxWidth={{
                base: '142px',
                md: '184px',
                lg: '174px',
                xl: '300px',
                '3xl': '488px',
              }}
              overflow='hidden'
              textOverflow='ellipsis'
              noOfLines='2'
              isExternal
            >
              {alertItem.productInfo.name}
            </Link>
            <Text
              color='purchr.gray.light'
              fontSize='10px'
              fontWeight='400'
              display={{ base: 'block', xl: 'none' }}
              pt='4px'
            >
              {moment(alertItem.scrapedAt).format('Do MMM @ h:mma')}
            </Text>
          </Box>
        </Flex>
      </GridItem>

      <GridItem
        minW='54px'
        colSpan='1'
        color='purchr.gray.light'
        fontSize='12px'
        fontWeight='600'
        display={{ base: 'none', lg: 'block' }}
      >
        <Tooltip
          label='Original price when added'
          hasArrow
          w='200px'
          placement='right'
        >
          <Box sx={{ display: 'inline-block' }}>
            ${parseFloat(alertItem.savedItemInfo.originalPrice).toFixed(2)}
          </Box>
        </Tooltip>
      </GridItem>

      <GridItem colSpan={{ base: '5', md: '4', lg: '3', xl: '2' }}>
        <Tag
          p='8px'
          minW='144px'
          bg='purchr.orange.light'
          color='white'
          borderRadius='4px'
          alignItems='flex-start'
          lineHeight='normal'
          gap='4px'
          ms={{ base: '24px', md: '12px', lg: '0px' }}
        >
          <Image src={ConFetti} w='14px' h='14px' />
          <TagLabel
            display='flex'
            alignSelf='end'
            gap='4px'
            fontSize='13px'
            lineHeight='normal'
          >
            <Text fontWeight='600'>
              ${parseFloat(alertItem.scrapedPrice).toFixed(2)}
            </Text>
            <Text fontWeight='400'>
              {`(${discountPercentagevalue})`}
            </Text>
          </TagLabel>
        </Tag>
      </GridItem>

      <GridItem
        minW='70px'
        colSpan='1'
        fontSize='12px'
        fontWeight='400'
        cursor='pointer'
        color='purchr.gray.light'
        display={{ base: 'none', lg: 'block' }}
      >
        <Box
          cursor='pointer'
          onClick={() => {
            localStorage.setItem('search', alertItem.productInfo.name);
            navigate('/');
          }}
        >{`Edit Item >`}</Box>
      </GridItem>

      <GridItem colSpan='1' display={{ base: 'none', lg: 'block' }}>
        <Tooltip
          label='Price drop found counter'
          hasArrow
          w='190px'
          placement='right'
        >
          <Tag
            minW='48px'
            p='8px'
            gap='6px'
            bg='#E6E6E6'
            borderRadius='4px'
            color='purchr.gray.light'
          >
            <Image src={BellRinging} w='10px' h='10px' />
            <TagLabel fontSize='13px' fontWeight='600'>
              {alertItem.currentCounter || 0}
            </TagLabel>
          </Tag>
        </Tooltip>
      </GridItem>
    </Grid>
  );
};

export default AlertHistoryItem;
