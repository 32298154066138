import purchrAxios from './purchrAxios';

export default class SavedItemsService {
  static async all() {
    const response = await purchrAxios.get(`/savedItems`);
    return response.data;
  }

  static async delete(savedItemId) {
    const response = await purchrAxios.delete(`/savedItems/${savedItemId}`);
    return response.data;
  }

  static async update(savedItemId, objectToBeUpdated) {
    const response = await purchrAxios.put(
      `/savedItems/${savedItemId}`,
      { ...objectToBeUpdated },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }
}
