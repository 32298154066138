import { auth } from '../firebase';

import firebase from 'firebase/compat/app';

export default class UserService {
  static async forgotPassword(email) {
    await auth.sendPasswordResetEmail(email);
  }

  static async confirmPasswordReset(code, email) {
    await auth.confirmPasswordReset(code, email);
  }

  static async sendVerificationEmail() {
    try {
      await auth.currentUser.sendEmailVerification();
    } catch (error) {
      console.log(error);
    }
  }

  static async verifyCode(code) {
    return await auth.checkActionCode(code);
  }

  static async changePassword(configs) {
    const { currentUser, oldPassword, newPassword } = configs;

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        oldPassword
      );

      await auth.currentUser.reauthenticateWithCredential(credential);
      await auth.currentUser.updatePassword(newPassword);
    } catch (error) {
      let message = error.message;
      if (
        error.message.includes('auth/invalid-login-credentials') ||
        error.message.includes('auth/invalid-credential') ||
        error.message.includes('auth/wrong-password')
      ) {
        message = 'Invalid old password.';
      }

      if (error.message.includes('auth/user-not-found')) {
        message = 'User not found';
      }

      if (error.message.includes('auth/invalid-email')) {
        message = 'Enter valid email';
      }

      return {
        error: message || 'Error while changing password',
      };
    }
  }
}
