import React, {
  createContext,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { auth } from '../firebase';

import { AdminConfigService, UsersService } from '../services';
import { GoogleAuthProvider } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router';
import { Box, Flex, Spinner } from '@chakra-ui/react';

export const AuthContext = createContext({
  currentUser: null,
  error: null,
  authPending: false,
  authenticateUser: (provider, data) => {},
  logoutUser: () => {},
  toggleError: (message = null) => {},
});

const AuthContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const [isAUthenticated, setIsAuthenticated] = useState(undefined);
  const [authPending, setAuthPending] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserMatched, setCurrentUserMatched] = useState(false);

  const history = useNavigate();
  const location = useLocation();

  const updateCurrentUser = useCallback(async (userInfo) => {
    const isViaExtension = location.search.includes('?via_extension');
    if (!userInfo) {
      userInfo = await UsersService.me(isViaExtension);

      if (isViaExtension) {
        setCurrentUser({ ...userInfo });

        history(`/extension-success?via_extension&token=${userInfo.token}`)
        return
      }
    }

    if (!userInfo) {
      setIsAuthenticated(false);
      let url = '/login'
      if(isViaExtension) {
        url = `/login?via_extension`
      }

      return history(url);
    }

    if (creatingAccount) {
      setCreatingAccount(false);
    }

    setCurrentUser({ ...userInfo });
    setAuthPending(false);
  }, []);

  useEffect(() => {
    const fetchAdminConfigInfo = async () => {
      if (currentUser) {
        const response = await AdminConfigService.all();
        if (response[0].ignoreSavedItemsFor.includes(currentUser.id)) {
          setCurrentUserMatched(true);
        }
      }
    };
    fetchAdminConfigInfo();
  }, [currentUser]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (creatingAccount) {
        return;
      }

      if (user) {
        return updateCurrentUser();
      }

      setIsAuthenticated(false);
    });

    return () => {
      unsubscribeAuth();
    };
  }, [creatingAccount]);

  useEffect(() => {
    if (currentUser === null) {
      return;
    }

    setIsAuthenticated(true);

    const pages = ['/register', '/forgot-password', '/login', '/onboarding'];
    if (pages.includes(location.pathname)) {
      const to = currentUser.onboardingCompleted ? '/' : '/onboarding';
      history(`${to}${location.search}`);
    }
  }, [currentUser, history, location.pathname]);

  if (isAUthenticated === undefined) {
    return (
      <Fragment>
        <Box h={`${window.innerHeight}px`}>
          <Flex
            h={`${window.innerHeight}px`}
            alignItems='center'
            justifyContent='center'>
            <Spinner size='xl' />
          </Flex>
        </Box>
      </Fragment>
    );
  }

  const authenticateUser = async (provider, data) => {
    try {
      setAuthPending(true);

      let response;
      if (provider !== 'EMAIL') {
        setCreatingAccount(true);

        const authProvider = new GoogleAuthProvider();

        response = await auth.signInWithPopup(authProvider);
        const profileInfo = response.additionalUserInfo.profile;

        let newUserInfo;
        if (response.additionalUserInfo.isNewUser) {
          const userInfo = {
            id: response.user.uid,
            email: profileInfo.email,
            termsPrivacyAccepted: data.termsPrivacyAccepted,
            isSocialUser: true,
            emailVerified: response.user.emailVerified,
          };

          console.log('Creating new user');
          newUserInfo = await UsersService.createUser(userInfo);
          console.log('New user created', newUserInfo);
        }

        await updateCurrentUser(
          newUserInfo
            ? {
                ...newUserInfo,
              }
            : null
        );

        return;
      }

      const { email, password } = data;

      if (data?.register) {
        setCreatingAccount(true);

        UsersService.validateUserInfo(data);
        response = await auth.createUserWithEmailAndPassword(email, password);

        const userInfo = {
          id: response.user.uid,
          email: data.email,
          termsPrivacyAccepted: data.termsPrivacyAccepted,
          isSocialUser: false,
          emailVerified: response.user.emailVerified,
        };

        console.log('Creating new user');
        const newUserInfo = await UsersService.createUser(userInfo);
        console.log('New user created', newUserInfo);

        await updateCurrentUser({
          ...newUserInfo,
        });

        return true;
      }

      await auth.signInWithEmailAndPassword(email, password);

      await updateCurrentUser();
    } catch (error) {
      let message = error.message;
      if (
        error.message.includes('auth/popup-closed-by-user') ||
        error.message.includes('auth/user-cancelled')
      ) {
        setAuthPending(false);
        return false;
      }

      if (error.message.includes('auth/invalid-login-credentials')) {
        message = 'Wrong credentials.';
      }

      if (error.message.indexOf('auth/email-already-in-use') > -1) {
        message = 'account already exists.';
      }

      if (error.message.indexOf('auth/user-not-found') > -1) {
        message = 'User not found';
      }

      if (error.message.indexOf('auth/invalid-email') > -1) {
        message = 'Enter valid email';
      }

      if (error.message.indexOf('auth/wrong-password') > -1) {
        message = 'The password is invalid.';
      }

      if (error.message.indexOf('auth/user-cancelled') > -1) {
        setAuthPending(false);
        return false;
      }

      setError(message);
      setAuthPending(false);

      return false;
    }
  };

  const logoutUser = async () => {
    await auth.signOut();

    setCurrentUser(null);
  };

  const toggleError = (message) => {
    setError(message);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        authPending,
        error,
        toggleError,
        authenticateUser,
        logoutUser,
        updateCurrentUser,
        currentUserMatched,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
