import React from 'react'
import purpleCircleImage from '../../assets/PurpleCircle.webp'
import orangeCircleImage from '../../assets/OrangeCircle.webp'
import iconCluster2 from '../../assets/IconCluster_2.svg'
import iconCluster1 from '../../assets/IconCluster_1.svg'
import { Box, Image } from '@chakra-ui/react'

const BackGroundUI = () => {
    return (
        <Box>
            <Image src={purpleCircleImage}
                w={{ base: '151px', md: '400px', lg: '540px', xl: '540px' }}
                position='fixed'
                top={{ base: '260px', md: 'unset' }}
                left={{ base: '-81px', md: 'unset' }}
                bottom={{ base: 'unset', md: '-65px', lg: '-76px' }}
                right={{ base: 'unset', md: '-65px', lg: '-86px' }}
            ></Image>
            <Image src={orangeCircleImage}
                w={{ base: '326px', md: '275px', lg: '375px' }}
                position='fixed'
                bottom={{ base: '68px', md: '-50px', lg: '-125px' }}
                right={{ base: '-74px', md: 'unset', lg: 'unset' }}
                left={{ base: 'unset', md: '-50px', lg: '-75px' }}
            ></Image>
            <Image src={iconCluster2}
                w={{ base: '40px', md: '60px', lg: '72px' }}
                opacity='14%'
                position='fixed'
                top={{ base: '544px', md: '440px', lg: '370px' }}
                left={{ base: '8px', md: '10px', lg: '103px', xl: '10px' }}
            ></Image>
            <Image src={iconCluster1}
                w={{ base: '72px', sm: '96px' }}
                opacity='14%'
                position='fixed'
                top={{ base: '8px', md: '10px', lg: '68px', '2xl': '16px' }}
                right={{ base: '8px', md: '10px', lg: '36px' }}
            ></Image>
        </Box>
    )
}

export default BackGroundUI