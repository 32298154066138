// Firebase
exports.firebaseConfig = {
  apiKey: 'AIzaSyAZfBAq23_i0K5yZNUlC78JoFEOAko7NTg',
  authDomain: 'purchr-ce58e.firebaseapp.com',
  projectId: 'purchr-ce58e',
  storageBucket: 'purchr-ce58e.appspot.com',
  messagingSenderId: '467045624397',
  appId: '1:467045624397:web:a0aa6f5ef943c7c4cb8a04',
  measurementId: 'G-NX1BM0TL1M',
};

// App
exports.ENV = process.env.ENV || 'production';