import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  Button,
  HStack,
  useToast,
  Spinner,
} from '@chakra-ui/react';

import HeroImage from '../../assets/Hero.webp';
import LogoImage from '../../assets/logo.svg';

import {
  PurchrSelect,
  PurchrInput,
  ContentHeader,
  BackGroundUI,
  SuccessBadge,
} from '../../components';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import SettingsRadio from '../../components/SettingsRadio';
import { LuBellRing } from 'react-icons/lu';
import { FaListUl } from 'react-icons/fa6';

import { UsersService, FirebaseService, EmailService } from '../../services';
import { AuthContext } from '../../context/auth.context';

import { useSearchParams } from 'react-router-dom';
import { getData } from 'country-list';
import { GoThumbsdown, GoThumbsup } from 'react-icons/go';

const steps = [
  {
    id: 1,
    title: 'Step 1',
  },
  {
    id: 2,
    title: 'Step 2',
  },
  {
    id: 3,
    title: 'Step 3',
  },
];

const priceAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'When drop detected',
    subTitle: 'Separate emails for every drop',
    icon: LuBellRing,
    selected: true,
  },
  {
    type: 'DAILY',
    title: 'Daily summary',
    subTitle: 'Maximum 1 email per day',
    icon: FaListUl,
  },
];

const temporaryAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'Recommend me',
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsup,
  },
  {
    type: 'DAILY',
    title: `Don't recommend`,
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsdown,
  },
];

const countryDatas = getData().map((countryData) => ({
  value: countryData.name,
  label: countryData.name,
  icon: countryData.code.toLowerCase(),
}));

export default function Onboarding() {
  const toast = useToast();

  const { currentUser, updateCurrentUser } = useContext(AuthContext);

  let [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(1);

  const [updatingUser, setUpdatingUser] = useState(false);

  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [country, setCountry] = useState(currentUser.country || 'Australia');

  const [settings, setSettings] = useState(currentUser.settings || {});

  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const verifyEmail = useCallback(
    async (code) => {
      try {
        setVerifyingEmail(true);

        const verificationResult = await FirebaseService.verifyCode(code);
        if (
          verificationResult.operation === 'VERIFY_EMAIL' &&
          verificationResult.data.email === currentUser.email
        ) {
          await UsersService.verifyEmail();
          updateCurrentUser({ ...currentUser, emailVerified: true });
        }

        setVerifyingEmail(false);
      } catch (error) {
        let message = error.message;
        if (error.message.includes('auth/user-not-found')) {
          message = 'User not found';
        } else if (
          error.message.includes('auth/expired-action-code') ||
          error.message.includes('auth/invalid-action-code')
        ) {
          message = 'Verification code is expired or invalid.';
        } else if (error.message.includes('auth/user-disabled')) {
          message = 'User is disabled.';
        }

        setVerifyingEmail(false);
        toast({
          title: message,
          status: 'error',
          duration: '2000',
        });
      }
    },
    [currentUser, toast, updateCurrentUser]
  );

  useEffect(() => {
    const code = searchParams?.get('oobCode');
    if (!currentUser?.emailVerified && code) {
      verifyEmail(code);
      return;
    }
  }, [currentUser, searchParams, verifyEmail]);

  const verifyUser = () => {
    const result = validateUser();
    if (result?.error) {
      return toast({
        title: result.error,
        status: 'error',
        duration: '2000',
      });
    }
    setCurrentStep(currentStep + 1);
  };

  const updateUser = async () => {
    const result = validateSetting();
    if (result?.error) {
      return toast({
        title: result.error,
        status: 'error',
        duration: '2000',
      });
    }

    setUpdatingUser(true);
    await UsersService.updateUser({
      firstName,
      lastName,
      country,
      settings,
      onboardingCompleted: true,
    });

    setUpdatingUser(false);
    setCurrentStep(currentStep + 1);

    updateCurrentUser({
      ...currentUser,
      firstName,
      lastName,
      country,
      settings,
      onboardingCompleted: true,
    });
  };

  const validateUser = () => {
    if (!firstName) {
      return { error: 'First name is required' };
    }

    if (!lastName) {
      return { error: 'Last name is required' };
    }
  };

  const validateSetting = () => {
    if (!settings || !settings.priceAlert || !settings.temporaryAlert) {
      return { error: 'Missing alert settings' };
    }
  };

  const nextAndPrevEle = (showPrevious, showNext, onNextClick) => {
    return (
      <Flex justifyContent={!showPrevious ? 'end' : 'space-between'}>
        {showPrevious ? (
          <Button
            variant='outline'
            mt='42px'
            leftIcon={<FiArrowLeft />}
            isDisabled={currentStep === 1}
            onClick={() => setCurrentStep(currentStep - 1)}>
            Back
          </Button>
        ) : null}
        {showNext ? (
          <Button
            variant='outline'
            mt='42px'
            rightIcon={<FiArrowRight />}
            isLoading={updatingUser}
            isDisabled={verifyingEmail || !currentUser.emailVerified}
            onClick={() => {
              if (onNextClick) {
                return onNextClick();
              }

              setCurrentStep(currentStep + 1);
            }}>
            Next
          </Button>
        ) : null}
      </Flex>
    );
  };

  const showToast = (toastInfo) => {
    toast({
      title: toastInfo.message,
      status: toastInfo.status,
      duration: '2000',
    });
  };

  const sendVerificationEmail = async () => {
    setSendingEmail(true);

    await EmailService.sendVerificationEmail();
    showToast({
      message: 'Verification email sent.',
      status: 'success',
    });

    setSendingEmail(false);
  };

  const step1Ele = () => {
    return (
      <Box>
        <PurchrInput
          lableProps={{
            mt: '20px',
            value: 'Email',
          }}
          inputProps={{
            type: 'email',
            isDisabled: true,
          }}
          value={currentUser.email}
        />
        <Box>
          <Flex alignItems='center'>
            <Button
              mt='4px'
              variant='solid'
              w='auto'
              fontSize='12px'
              p='8px 12px'
              isLoading={sendingEmail}
              isDisabled={currentUser.emailVerified || verifyingEmail}
              onClick={sendVerificationEmail}>
              {`Verify email  >`}
            </Button>
            {verifyingEmail ? (
              <Spinner ml='12px' size='sm' color='purchr.orange.light' />
            ) : null}
          </Flex>
        </Box>
        <SuccessBadge
          visibility={currentUser.emailVerified ? 'visible' : 'hidden'}
          mt='8px'
          description='Email verified'
          btnText='Success'
        />
        <Box>{nextAndPrevEle(false, true)}</Box>
      </Box>
    );
  };

  const step2Ele = () => {
    return (
      <Box>
        <HStack mt='20px'>
          <PurchrInput
            lableProps={{
              value: 'First Name',
            }}
            inputProps={{
              type: 'text',
              placeholder: 'Peter',
            }}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <PurchrInput
            lableProps={{
              value: 'Last Name',
            }}
            inputProps={{
              type: 'text',
              placeholder: 'Parker',
            }}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />  
        </HStack>
        <PurchrSelect
          boxProps={{
            mt: '20px',
          }}
          lableProps={{
            value: 'Country',
          }}
          value={country}
          data={countryDatas}
          isSearchable={false}
          onChange={(country) => setCountry(country.value)}
          externalStyle={{
            valueContainer: (provided) => ({
              ...provided,
              display: 'flex',
            }),
          }}
          >
        </PurchrSelect>
        {nextAndPrevEle(true, true,verifyUser)}
      </Box>
    );
  };

  const step3Ele = () => {
    return (
      <Box>
        <Flex
          flexDirection='column'
          gap='16px'
          h={{ base: '220px', sm: 'auto' }}
          overflow='auto'
        >
          <Box>
            <Text
              my='16px'
              fontWeight='500'
              fontSize='12px'
              color='purchr.gray.700'
            >
              Price Alert Settings
            </Text>
            <SettingsRadio
              selectedType={settings.priceAlert}
              settings={priceAlertOptions}
              onValueChange={(value) => {
                setSettings({
                  ...settings,
                  priceAlert: value,
                });
              }}
              group='settings1'
            />
          </Box>
          <Box>
            <Text
              mb='16px'
              fontWeight='500'
              fontSize='12px'
              color='purchr.gray.700'>
              Recommendations
            </Text>
            <SettingsRadio
              selectedType={settings.temporaryAlert}
              settings={temporaryAlertOptions}
              onValueChange={(value) => {
                setSettings({
                  ...settings,
                  temporaryAlert: value,
                });
              }}
              group='settings2'
            />
          </Box>
        </Flex>
        {nextAndPrevEle(true, true, updateUser)}
      </Box>
    );
  };

  return (
    <Box
      bg='purchr.bgHero'
      h={`${window.innerHeight}px`}
      p={{ base: '24px', sm: '0px' }}>
      <BackGroundUI />
      <Grid
        h='100%'
        templateColumns='repeat(10, 1fr)'
        gap='16px'
        alignItems='center'>
        <GridItem colSpan={{ base: 10, xl: 5 }}>
          <Box>
            <Flex h={'100%'} direction={'column'} alignItems={'center'}>
              <Image src={LogoImage} height='100px' mb='24px' zIndex='1' />
              <Card
                p={
                  currentStep === 3
                    ? { base: '48px 28px', sm: '90px 75px' }
                    : { base: '48px', sm: '90px 75px' }
                }
                borderRadius='42px'
                w={{ base: 'auto', sm: '544px' }}>
                <CardBody p='0px'>
                  <ContentHeader
                    size='small'
                    title='Complete setup'
                    description='Start saving on your favourite items now!'
                    descriptionStyle={{ mt: '8px' }}
                  />
                  <Flex gap='8px' userSelect='none' mt='16px'>
                    {steps.map((step, index) => {
                      return (
                        <Box
                          flex='1'
                          key={index}
                          h='40px'
                          p='8px'
                          borderBottom='1px solid'
                          borderColor={
                            step.id === currentStep
                              ? 'purchr.brown.dark'
                              : 'purchr.gray.light'
                          }>
                          <Text
                            color={
                              step.id === currentStep
                                ? 'purchr.brown.dark'
                                : 'purchr.gray.light'
                            }
                            fontWeight='400'
                            fontSize='16px'>
                            {step.title}
                          </Text>
                        </Box>
                      );
                    })}
                  </Flex>
                  {currentStep === 1
                    ? step1Ele()
                    : currentStep === 2
                    ? step2Ele()
                    : currentStep === 3
                    ? step3Ele()
                    : null}
                </CardBody>
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={5}
          display={{ base: 'none', xl: 'block' }}
          alignSelf='start'
          mt='60px'>
          <Box>
            <Flex h={'100%'} direction={'column'}>
              <Text
                mt='24px'
                alignSelf='center'
                maxW='397px'
                as='span'
                color='purchr.blue.dark'
                fontWeight='400'
                fontSize='24px'>
                Alerting you to
                <Text
                  as='span'
                  color='purchr.brown.dark'
                  fontWeight='700'
                  fontSize='24px'
                  ml='6px'>
                  price drops
                </Text>{' '}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h='100%' alignItems='center' justifyContent='center'>
                  <Image src={HeroImage} zIndex='1' />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
