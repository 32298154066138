import React from 'react';
import {
  Box,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import css from './index.module.css';

export default function PurchrInput({
  boxProps = {},
  lableProps = {},
  inputProps = {},
  inputLeftIconProps = {},
  onChange,
  onBlur,
  onKeyDown,
  value,
  icon,
}) {
  return (
    <Box {...boxProps}>
      <Text
        className={css.textModule}
        fontSize='12px'
        fontWeight='500'
        color='purchr.gray.700'
        {...lableProps}
      >
        {lableProps.value}
      </Text>
      <InputGroup>
        {icon ? (
          <InputLeftElement
            fontSize={20}
            {...inputLeftIconProps}
            children={icon}
          />
        ) : null}
        <Input
          size='sm'
          mt='4px'
          fontWeight='400'
          borderRadius='6px'
          borderColor='purchr.gray.300'
          _disabled={{
            opacity: '0.4',
            cursor: 'unset !important',
          }}
          _placeholder={{
            color: 'purchr.gray.500',
            fontWeight: '400',
            fontSize: '12px',
          }}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          {...inputProps}
        />
      </InputGroup>
    </Box>
   
  );
}
