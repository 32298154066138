import React, { createContext, useContext, useEffect, useState } from 'react';

import { cloneDeep } from 'lodash';
import { AuthContext } from './auth.context';

export const GlobalContext = createContext({
  savedItemData: {},
});

const GlobalContextProvider = (props) => {
  const [allSavedItems, setAllSavedItems] = useState([]);

  const [allTags, setAllTags] = useState([]);

  const [itemsRemaining, setItemsRemaining] = useState(
    allSavedItems
      .map((savedItem) => savedItem.isActivated)
      .filter((item) => item === true).length || 0
  );
  const { currentUser } = useContext(AuthContext);

  const updateAllTags = async (newTags) => {
    setAllTags((prevTags) => [...prevTags, ...newTags]);
  };

  const initializeSavedItems = (savedItems) => {
    setAllSavedItems(cloneDeep(savedItems));
  };

  const updateSavedItem = async (updatedData) => {
    setAllSavedItems((prev) =>
      prev.map((data) => {
        return data._id === updatedData._id
          ? { ...data, ...updatedData }
          : { ...data };
      })
    );
  };

  const deleteSavedItem = async (savedItemId) => {
    setAllSavedItems((prev) => prev.filter((data) => data._id !== savedItemId));
  };

  useEffect(() => {
    setAllTags([]);
  }, [currentUser]);

  useEffect(() => {
    const savedItemsLength =
      allSavedItems &&
      allSavedItems
        .map((savedItem) => savedItem.isActivated)
        .filter((item) => item === true).length;

    setItemsRemaining(savedItemsLength);
    if (allSavedItems.length === 0) {
      setAllTags(["Clothes",  "Gifts", "Groceries", "Random"]);
    }
  }, [currentUser, allSavedItems]);

  return (
    <GlobalContext.Provider
      value={{
        allTags,
        updateAllTags,
        allSavedItems,
        initializeSavedItems,
        updateSavedItem,
        deleteSavedItem,
        itemsRemaining,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
