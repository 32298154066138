import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router';
import { AppLayout, ExtentionSuccess } from './components';
import {
  ForgotPassword,
  Login,
  Register,
  Onboarding,
  MyAccount,
  AlertHistory,
  WatchList,
} from './pages';
import PrivateRoute from './PrivateRoute';

import AuthProvider from './context/auth.context';
import GlobalProvider from './context/global.context';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <GlobalProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path='/extension-success' element={<ExtentionSuccess />} />
              <Route path='/onboarding' element={<Onboarding />} />
              <Route element={<AppLayout />}>
                <Route index element={<WatchList />} />
                <Route path='/my-account' element={<MyAccount />} />
                <Route path='/alerts' element={<AlertHistory />} />
              </Route>
            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
          </Routes>
        </GlobalProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
