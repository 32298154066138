import { useState } from 'react';
import style from './index.module.css';

import { Box, Checkbox, CheckboxGroup, Flex, Icon } from '@chakra-ui/react';

import { IoSearchSharp } from 'react-icons/io5';
import PurchrInput from '../PurchrInput';

const SearchItems = ({ filterItemDatas, handleSearch, children,rightChildren }) => {
  const [selectedCategories, setSelectedCatgories] = useState([]);

  const q = localStorage.getItem('search');
  const [searchQuery, setSearchQuery] = useState(q || '');

  const handleSelectedCategories = (category) => {
    if (selectedCategories.includes(category)) {
      selectedCategories.splice(selectedCategories.indexOf(category), 1);
      setSelectedCatgories([...selectedCategories]);
    } else {
      setSelectedCatgories([...selectedCategories, category]);
    }
  };

  return (
    <Box
      padding={{ base: '20px 0 8px 0', lg: '24px 0 8px 0' }}
      borderBottom='1px solid #E6E6E6'
    >
      <Flex
        justifyContent='space-between'
        alignItems={{ base: 'normal', lg: 'center' }}
        flexDir={{ base: 'column-reverse', lg: 'row' }}
      >
        <CheckboxGroup colorScheme='#4F0A43'>
          <Flex alignItems='center' flexWrap='wrap' gap='8px'>
            {children}
            {(filterItemDatas || []).map((filterItemData, index) => (
              <Checkbox
                className={style.checkboxRound}
                key={index}
                value={filterItemData}
                size='sm'
                fontWeight='700'
                color='purchr.gray.700'
                padding={{ base: '4px 20px 4px 8px', lg: '4px 8px ' }}
                border='0.7px solid'
                borderColor='purchr.gray.300'
                spacing='4px'
                boxShadow='0px 0.7px 1.4px 0px rgba(16, 24, 40, 0.05);'
                borderRadius='6px'
                bg='white'
                _checked={{ bg: '#4F0A43', color: 'white', size: 'xs' }}
                onChange={() => handleSelectedCategories(filterItemData)}
              >
                {filterItemData}
              </Checkbox>
            ))}
            {rightChildren}
          </Flex>
        </CheckboxGroup>
        <PurchrInput
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            handleSearch(e.target.value);
          }}
          // onBlur={(e) => {
          //   handleSearch(e.target.value);
          // }}
          boxProps={{
            maxWidth: { base: '100%', lg: '250px' },
            mb: { base: '8px', lg: '0' },
          }}
          icon={<Icon as={IoSearchSharp} fill='black' fillOpacity='0.3' />}
          inputLeftIconProps={{
            height: '100%',
            fontSize: '16px',
          }}
          inputProps={{
            mt: '0',
            type: 'search',
            placeholder: 'Search',
            bg: 'white',
            border: '1px solid #D8D8D8',
            color: 'purchr.gray.light',
            fontWeight: '700',
            _placeholder: {
              color: 'purchr.gray.dark',
            },
          }}
        ></PurchrInput>
      </Flex>
    </Box>
  );
};
export default SearchItems;
