import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  Image,
  Card,
  CardBody,
  Text,
  Button,
  useToast,
} from '@chakra-ui/react';

import {
  BackGroundUI,
  ContentHeader,
  PurchrInput,
  SuccessBadge,
} from '../../components';

import GoogleIcon from '../../assets/google.svg';

import HeroImage from '../../assets/Hero.webp';
import LogoImage from '../../assets/logo.svg';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth.context';

export default function Login() {
  const toast = useToast();
  const history = useNavigate();
  const params = useLocation();

  const { authPending, authenticateUser, error, toggleError } =
    useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [provider, setProvider] = useState('');

  if (error) {
    toast({
      title: error,
      status: 'error',
      duration: '2000',
    });

    toggleError();
  }

  const handleEnter = (event, provider) => {
    if (event.key === 'Enter') {
      handleButtonClick(event, provider)
    }
  }

  const handleButtonClick = async (event, provider) => {
    event.preventDefault();

    setProvider(provider);

    const authenticated = await authenticateUser(provider, {
      email,
      password,
    });

    if (authenticated) {
      history('/');
    }
  };

  return (
    <Box
      bg='purchr.bgHero'
      h={`${window.innerHeight}px`}
      p={{ base: '0', sm: '0px', xl: '0 40px 0 40px' }}>
      <BackGroundUI />
      <Grid
        h='100%'
        templateColumns='repeat(10, 1fr)'
        gap='16px'
        alignItems='center'>
        <GridItem colSpan={{ base: 10, xl: 5, '2xl': 4 }}>
          <Box>
            <Flex h='100%' direction='column' alignItems='center'>
              <Image src={LogoImage} height='100px' mb='24px' zIndex={1} />
              <Card
                p={{ base: '48px', sm: '90px 75px' }}
                borderRadius='42px'
                w={{ base: 'auto', sm: '544px' }}>
                <CardBody p='0px'>
                  <ContentHeader
                    size='small'
                    title='Log in to your account'
                    description='Welcome back! Please enter your details.'
                    descriptionStyle={{ mt: '8px' }}
                  />
                  <SuccessBadge
                    display={
                      params.search.includes('mode=verifyEmail')
                        ? 'flex'
                        : 'none'
                    }
                    mt='12px'
                    descriptionStyle={{ display: 'block' }}
                    description='Verification successful, please sign in again'
                    btnText='Success'
                  />
                  <PurchrInput
                    boxProps={{
                      mt: '12px',
                    }}
                    lableProps={{
                      value: 'Email',
                    }}
                    inputProps={{
                      type: 'email',
                      placeholder: 'Enter your email',
                    }}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    onKeyDown={(event) => handleEnter(event, 'EMAIL')}
                  />
                  <PurchrInput
                    boxProps={{
                      mt: '16px',
                    }}
                    lableProps={{
                      value: 'Password',
                    }}
                    inputProps={{
                      type: 'password',
                      placeholder: 'Enter your password',
                    }}
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    onKeyDown={(event) => handleEnter(event, 'EMAIL')}
                  />
                  <Box my='20px' textAlign='end'>
                    <NavLink to='/forgot-password'>
                      <Text
                        color='purchr.brown.dark'
                        fontWeight='600'
                        fontSize='12px'
                        cursor='pointer'>
                        Forgot password?
                      </Text>
                    </NavLink>
                  </Box>
                  <Button
                    variant='solid'
                    isLoading={provider === 'EMAIL' && authPending}
                    onClick={(event) => handleButtonClick(event, 'EMAIL')}>
                    Sign in
                  </Button>
                  <Button
                    variant='outline'
                    w='100%'
                    _hover={{ bg: 'transparent' }}
                    mt='12px'
                    _loading={ { p: '0px'}}
                    isLoading={provider === 'GOOGLE' && authPending}
                    onClick={(event) => handleButtonClick(event, 'GOOGLE')}>
                    <Image src={GoogleIcon} alt='Google' w='16px' mr='12px' />
                    <Text color='purchr.gray.700'>Sign in with Google</Text>
                  </Button>
                  <Box
                    textAlign='center'
                    fontSize='xs'
                    fontWeight='500'
                    color='purchr.gray.500'
                    mt='24px'>
                    <Text as='span'>Don’t have an account?</Text>
                    <NavLink to='/register'>
                      <Text
                        as='span'
                        color='purchr.brown.dark'>{` Sign up >`}</Text>
                    </NavLink>
                  </Box>
                </CardBody>
              </Card>
            </Flex>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 0, xl: 5, '2xl': 6 }}
          display={{ base: 'none', xl: 'block' }}
          alignSelf='start'
          mt='60px'>
          <Box>
            <Flex h={'100%'} direction={'column'}>
              <Text
                mt='24px'
                maxW='397px'
                as='span'
                alignSelf='center'
                color='purchr.blue.dark'
                fontWeight='400'
                fontSize='24px'>
                Alerting you to
                <Text
                  as='span'
                  color='purchr.brown.dark'
                  fontWeight='700'
                  fontSize='24px'
                  ml='6px'>
                  price drops
                </Text>{' '}
                on your favourite items, everywhere.
              </Text>
              <Box h={`calc(${window.innerHeight}px - 156px)`}>
                <Flex h='100%' alignItems='center' justifyContent='center'>
                  <Image src={HeroImage} zIndex='1' />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
