import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();
  const isRecommended = props.title.toLowerCase().includes('recommend');

  return (
    <Box as='label' flex='1' minW={{ base: '150px', sm: '170px' }}>
      <input {...input} />
      <Box
        {...checkbox}
        p='8px 12px'
        color='purchr.gray.700'
        cursor='pointer'
        border='1px solid #D0D5DD'
        borderRadius='8px'
        boxShadow='0 1px 2px 0 rgba(16, 24, 40, 0.05)'
        _checked={{
          bg: 'purchr.brown.dark',
          color: 'white',
        }}>
        <Flex
          flexDirection='column'
          justifyContent='flex-end'
          alignItems='flex-start'
        >
          <Flex w='100%' justifyContent='space-between'>
            <Icon
              as={props.icon}
              mt={isRecommended ? 0 : '4'}
              w={props.isChecked ? '20px' : '16px'}
              h={props.isChecked ? '20px' : '16px'}
            />
            <Box
              display='flex'
              flexDirection='column'
              p='4px 2px 3px 2px'
              visibility={props.isChecked ? 'visible' : 'hidden'}
            >
              <Icon as={FaRegCircleCheck} w='16px' h='16px' />
            </Box>
          </Flex>
          <Box>
            <Text
              mt='12px'
              fontSize={{ base: '12px', sm: '14px' }}
              fontWeight='700'>
              {props.title}
            </Text>
            <Text
              fontSize={{ base: '8px', sm: '9px' }}
              fontWeight='400'
              maxW={{base:'120px',sm:'140px'}}
            >
              {props.subTitle}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

function SettingsRadio({ settings, onValueChange, name, selectedType }) {
  const defaultSetting = settings.find(
    (setting) => setting.type === selectedType
  );

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name,
    defaultValue: defaultSetting?.type,
    onChange: onValueChange
  });

  useEffect(() => {
    setValue(selectedType)
  }, [selectedType, setValue]);

  const group = getRootProps();

  return (
    <HStack {...group}>
      {settings.map((setting, index) => {
        const radio = getRadioProps({ ...setting, value: setting.type });
        return <RadioCard key={index} {...radio} />;
      })}
    </HStack>
  );
}
export default SettingsRadio;
