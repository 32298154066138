import purchrAxios from './purchrAxios';

export default class AlertItemsService {
  static async all(paginationInfo) {
    const response = await purchrAxios.post(
      `/alertItems/search`,
      paginationInfo,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }
}
