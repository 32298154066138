const breakpoints = {
  base: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1400px',
  '3xl': '1500px',
};

export const coreTheme = {
  breakpoints,
  colors: {
    purchr: {
      bgHero: '#FEC536',
      header: '#101828',
      subHeader: '#667085',
      gray: {
        bg: '#F3F3F3',
        light: '#A7A7A7',
        300: '#D0D5DD',
        500: '#667085',
        700: '#6C6C6C',
        dark: '#0000004D'
      },
      orange: {
        light: '#F5832A',
      },
      brown: {
        dark: '#4F0A43',
      },
      blue: {
        dark: '#2A303E',
      },
    },
  },
  fonts: {
    body: `'Inter', sans-serif`,
    heading: `'Inter', sans-serif`,
  },
};
