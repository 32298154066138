import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import SettingsRadio from '../../../components/SettingsRadio';
import { LuBellRing } from 'react-icons/lu';
import { FaListUl } from 'react-icons/fa6';
import { GoThumbsup, GoThumbsdown } from "react-icons/go";

import { AuthContext } from '../../../context/auth.context';
import { UsersService } from '../../../services';

const priceAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'When drop detected',
    subTitle: 'Separate emails for every drop',
    icon: LuBellRing,
  },
  {
    type: 'DAILY',
    title: 'Daily summary',
    subTitle: 'Maximum 1 email per day',
    icon: FaListUl,
  },
];

const temporaryAlertOptions = [
  {
    type: 'WHEN_DROP_DETECTED',
    title: 'Recommend me',
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsup,
  },
  {
    type: 'DAILY',
    title: `Don't recommend`,
    subTitle: 'If we find your item at a better price at a different store',
    icon: GoThumbsdown,
  },
];

const Notification = () => {
  const toast = useToast();
  const isMobileSize = window.innerWidth <= 425;

  const { currentUser, updateCurrentUser } = useContext(AuthContext);
  const [settings, setSettings] = useState(currentUser.settings || {});
  const [updatingSettings, setUpdatingSettings] = useState(false);

  const updateSetting = async () => {
    setUpdatingSettings(true);

    await UsersService.updateUser({
      settings,
    });

    setUpdatingSettings(false);
    updateCurrentUser({
      ...currentUser,
      ...settings,
    });

    toast({
      title: 'Info updated successfully.',
      status: 'success',
      duration: '2000',
    });
  };

  const resetForm = () => {
    setUpdatingSettings(true);
    setSettings({
      ...(currentUser.settings || {}),
    });

    setUpdatingSettings(false);
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        padding={{ base: '0', sm: '0 0 18px 0' }}
        borderBlockEnd={{ base: '0', sm: '1px' }}
        borderBlockEndColor={{ base: '', sm: '#EAECF0' }}
        order={{ base: 2, sm: 1 }}>
        <Box display={{ base: 'none', sm: 'block' }}>
          <Text fontSize='16px' fontWeight='500' color='#101828'>
            Notification settings
          </Text>
          <Text fontSize='12px' fontWeight='400' color='purchr.gray.light'>
            Manage what and when you are alerted by our platform
          </Text>
        </Box>
        <Box display='flex' gap='10px' mt={{ base: '20px', sm: '0' }} mb={{ base: '24px', sm: '0' }}>
          <Button
            variant='outline'
            w='100%'
            minW='80px'
            color='#6C6C6C'
            onClick={resetForm}>
            Reset
          </Button>
          <Button
            variant='solid'
            minW='80px'
            isLoading={updatingSettings}
            onClick={updateSetting}>
            Save
          </Button>
        </Box>
      </Box>
      <Flex
        direction='column'
        gap={{ base: '11px', xl: '18px' }}
        mt={{ base: '24px', xl: '22px' }}
        order={{ base: 1, sm: 2 }}>
        <Box maxW={{ lg: '70%', xl: '50% ', '2xl': '45%' }}>
          <Text
            mb='18px'
            minW='135px'
            color='#6C6C6C'
            fontSize='12px'
            fontWeight='500'
            display='flex'
            justifyContent='start'>
            Price drop alerts
          </Text>
          <Box display='flex' justifyContent={isMobileSize ? 'center' : 'end'}>
            <SettingsRadio
              settings={priceAlertOptions}
              selectedType={settings.priceAlert}
              onValueChange={(value) => {
                setSettings({
                  ...settings,
                  priceAlert: value,
                });
              }}
              name='settings1'
            />
          </Box>
        </Box>
        <Box maxW={{ lg: '70%', xl: '50% ', '2xl': '45%' }}>
          <Text
            mb='18px'
            minW='135px'
            color='#6C6C6C'
            fontSize='12px'
            fontWeight='500'
            display='flex'
            justifyContent='start'>
           Recommendations
          </Text>
          <Box
            flex='30%'
            display='flex'
            justifyContent={isMobileSize ? 'center' : 'end'}>
            <SettingsRadio
              settings={temporaryAlertOptions}
              selectedType={settings.temporaryAlert}
              onValueChange={(value) => {
                setSettings({
                  ...settings,
                  temporaryAlert: value,
                });
              }}
              name='settings2'
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Notification;
