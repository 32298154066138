import React, { useEffect, useRef, useState } from 'react';

import {
  AlertHistoryDropDown,
  AlertHistoryItem,
  ContentHeader,
  ItemNotFound,
  SearchItems,
} from '..';
import { Box, Flex, Spinner, Text, Image } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { AlertItemsService } from '../../services';

import InfiniteScroll from 'react-infinite-scroller';

import DataError from '../../assets/Data_error.svg';
import { Fragment } from 'react';

const frequency = ['Today', 'Last 7 days'];

const filterItemDatas = [
  // 'Filter ABC',
  // 'Filter XYZ',
  // 'Filter PQR',
  // 'Filter ASD',
];

const AlertHistoryComponent = () => {
  const [fetchingAlertItems, setFetchingAlertItems] = useState(false);

  const [isMobileSize] = useMediaQuery('(max-width: 425px)');

  const [hasMoreItems, setHasMoreItems] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');
  const [showEmpty, setShowEmpty] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAlertItems, setCurrentPageAlertItems] = useState([]);

  const scrollContainerRef = useRef(null);

  let productInfo = localStorage.getItem('alertSearch');
  productInfo = productInfo ? JSON.parse(productInfo) : null;

  const [selectedItem, setSelectedItem] = useState(productInfo || null);

  localStorage.removeItem('alertSearch');

  const [allProducts, setAllProducts] = useState([]);

  const [groupedAlertItems, setGroupedAlertItems] = useState({});

  const loadMoreAlertItems = async () => {
    if (fetchingAlertItems || !hasMoreItems) {
      return;
    }

    setFetchingAlertItems(true);
    const body = {
      limit: 10,
      pageNo: currentPage,
      q: searchQuery,
    };

    if (selectedItem) {
      body.productId = selectedItem._id;
    }

    const alertItemsInfo = await AlertItemsService.all(body);

    setHasMoreItems(alertItemsInfo.hasMoreItems);

    const alertItems = alertItemsInfo.records;

    setCurrentPageAlertItems((prevItems) => [...prevItems, ...alertItems]);
    setAllProducts((prevItems) => [...prevItems, ...alertItems]);
    setFetchingAlertItems(false);

    setCurrentPage((prevPage) => prevPage + 1);

    if (alertItems.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
  };

  const handleSelectItem = async (alertItem) => {
    setSelectedItem({ ...alertItem });

    setFetchingAlertItems(true);

    const body = {
      limit: 10,
      pageNo: 1,
      q: searchQuery,
      productId: alertItem?._id,
    };

    const alertItemsInfo = await AlertItemsService.all(body);
    setHasMoreItems(alertItemsInfo.hasMoreItems);

    const alertItems = alertItemsInfo.records;

    setCurrentPageAlertItems(alertItems);
    setAllProducts([...allProducts, ...alertItems]);
    setCurrentPage(2);

    setFetchingAlertItems(false);
  };

  const handleClearSelection = async () => {
    if (!selectedItem) {
      return;
    }

    setSelectedItem(null);

    setFetchingAlertItems(true);
    const body = {
      limit: 10,
      pageNo: 1,
      q: searchQuery,
    };

    const alertItemsInfo = await AlertItemsService.all(body);
    setHasMoreItems(alertItemsInfo.hasMoreItems);

    const alertItems = alertItemsInfo.records;
    if (alertItems.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
    setCurrentPageAlertItems(alertItems);
    setAllProducts((prevItems) => [...prevItems, ...alertItems]);
    setCurrentPage(2);

    setFetchingAlertItems(false);
  };

  const handleSearch = async (searchQuery) => {
    searchQuery = searchQuery.toLowerCase();
    setFetchingAlertItems(true);
    const body = {
      limit: 10,
      pageNo: 1,
      q: searchQuery,
      productId: selectedItem?._id || null,
    };

    const alertItemsInfo = await AlertItemsService.all(body);
    setHasMoreItems(alertItemsInfo.hasMoreItems);

    const alertItems = alertItemsInfo.records;
    setCurrentPageAlertItems(alertItems);
    setCurrentPage(2);

    if (alertItems.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }

    setSearchQuery(searchQuery);
    setFetchingAlertItems(false);
  };

  return (
    <Box>
      <ContentHeader
        title='Alert History'
        description='Review all your saved items, set price change alerts'
        headingStyle={{
          color: 'purchr.brown.dark',
          fontSize: { base: '32px', sm: '44px' },
          fontWeight: '700',
        }}
        descriptionStyle={{
          fontWeight: '400',
          fontSize: '14px',
          color: '#C0C0C0',
          display: { base: 'none', md: 'block' },
        }}
      />

      <SearchItems
        filterItemDatas={filterItemDatas}
        handleSearch={handleSearch}>
        <AlertHistoryDropDown
          currentPageAlertItems={allProducts}
          selectedItem={selectedItem}
          handleSelectItem={handleSelectItem}
          handleClearSelection={handleClearSelection}
        />
      </SearchItems>

      <Box
        mt={{ base: '8px', sm: '20px' }}
        height={{
          base: `calc(${window.innerHeight}px - 350px)`,
          lg: `calc(${window.innerHeight}px - 300px)`,
        }}
        overflowY='auto'
        style={{ scrollbarWidth: isMobileSize ? 'none' : 'thin' }}
        ref={scrollContainerRef}>
        {fetchingAlertItems && !currentPageAlertItems?.length ? (
          <Flex justifyContent='center' alignItems='center' height='100%'>
            <Spinner size='md' />
          </Flex>
        ) : showEmpty ? (
          <ItemNotFound />
        ) : (
          <Fragment>
            <Text
              m='0px 20px'
              fontSize='10px'
              color='purchr.gray.light'
              fontWeight='400'>
              {frequency[0]}
            </Text>

            <InfiniteScroll
              loadMore={loadMoreAlertItems}
              getScrollParent={() => {
                return scrollContainerRef.current;
              }}
              threshold={0}
              hasMore={!fetchingAlertItems && hasMoreItems}
              useWindow={false}
            >
              {currentPageAlertItems.map((alertItem, index) => (
                <Box key={index}>
                  <AlertHistoryItem alertItem={alertItem} />
                </Box>
              ))}
            </InfiniteScroll>
          </Fragment>
        )}
        {fetchingAlertItems && hasMoreItems ? (
          <Flex my='10px' alignItems='center' justifyContent='center'>
            <Spinner size='md' />
          </Flex>
        ) : null}
      </Box>
    </Box>
  );
};

export default AlertHistoryComponent;
